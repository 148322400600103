

body {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  text-align: center;
  font-size: 21px;
  font-weight: 200;
}

h1 {
  font-family: 'Crimson';
  font-weight: 200;
}

a {
  color: white;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}




::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F2B111;
  ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./fonts/NeueHaasDisplay-Roman.woff2') format('woff2'),
      url('./fonts/NeueHaasDisplay-Roman.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./fonts/NeueHaasDisplay-Roman.woff2') format('woff2'),
      url('./fonts/NeueHaasDisplay-Roman.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson';
  src: url('./fonts/Crimson-Roman.woff2') format('woff2'),
      url('./fonts/Crimson-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}





@media screen and (max-width: 600px) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 32px;
  }
}