.schedule {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.schedule-content {
    width: 300px;
    display: flex;
    align-items: center;
}

.schedule-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 20px;
}

.schedule-item {
    display: flex;
    align-items: flex-start;
    text-align: left;
}

.schedule-item-time {
    width: 200px;
    text-align: left;
}

.schedule-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}