.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 5%;
}

.content {
    width: 40%;
    min-width: 300px;
    text-align: left;
}

.about_heading {
    text-align: center;
}